const axios = require('axios').default;

if(document.querySelector('#SyncVendorButton')) {
    document.appVendorSyncButton = new Vue({
        el: '#SyncVendorButton',
        name: "Vendor Sync Button",
        data: {
            SyncDataIsLoading: false,
            btnSync: 'Update Vendor List'
        },
        methods: {
            syncWithCT: function() {
              let url = '/settings/vendors/sync';
              let self=this;
              this.SyncDataIsLoading = true;
              this.btnSync = 'Updating... Please wait.'
              axios.post(url)
                  .then(function (response) {
                      self.SyncDataIsLoading = false;
                      self.Sync = 'Sync List With CT'
                      window.location.reload(true);
                      //console.log(response);
                  })
                  .catch(function (error) {
                      alert("There has been an error while updating. Please try again later.")
                      console.log(error);
                  });
            }
            
        },    
        delimiters: ["<%", "%>"]
    })
}
