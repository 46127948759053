<template>
    <div>
        <div class="modal fade" id="consolidate-po">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Consolidate PO</span>
                    </div>
                    <div class="modal-body popup-body">                        
                        <p>
                            Are you sure you want to consolidate this PO? Further editing will not be possible.
                        </p>
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" :disabled="! isFilled" data-dismiss="modal" @click="confirm">Yes, Consolidate</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Abort</button>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="isFilled" class="buttons" data-toggle="modal" data-target="#consolidate-po" :disabled="! isUnblocked">Consolidate PO</button>
        <button v-else class="buttons" :disabled="! isUnblocked" @click="abort">Consolidate PO</button>
    </div>
</template>

<script>
  export default {
      name: "ConsolidatePo",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          },
          abort: function () {
              this.$emit('aborted');
          },
      },
      props:['is-unblocked', 'is-filled'],
  }
</script>
