<template>
  <vue-editor class="setting-emails" v-model="emailBody" :editor-toolbar="customToolbar"></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: "NewPoEmail",
  data: function () {
    return {
      emailBody: '',
      customToolbar: [
        [{ 'size': [] }],
        [ 'bold', 'italic', 'underline', 'strike' ],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['blockquote'],
      ]
    }
  },
  components: {
    VueEditor
  },
  mounted() {
    this.emailBody = document.querySelector('#hidden-content').innerHTML;
  }
}
</script>

<style scoped>

</style>