<template>
    <div>
        <div class="modal fade" id="uncancel-po-items">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Undo cancel items</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to undo cancel the selected items? You need to enter the confirmed quantity.</span>
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Undo Cancel items</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Abort</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="buttons" v-if="isVisible" data-toggle="modal" data-target="#uncancel-po-items">Undo Cancel Items</button>
    </div>
</template>

<script>
  export default {
      name: "UnCancelPoItems",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          }
      },
      props:['is-visible']
  }
</script>
