<template>
    <div>
        <div class="modal fade" id="resendCT">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Are you sure?</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to resend the bid-sheet to Crunch Time?</span>
                    </div>
                    <div class="popup-buttons">
                        <slot></slot>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="buttons" data-toggle="modal" data-target="#resendCT">Resend to CT</button>
    </div>
</template>

<script>
export default {
    name: "ResendToCrunchTime",
    methods: {
        confirm: function () {
          this.$emit('confirmed');
        }
    },
}
</script>
