<template>
  <div v-if="items" class="row" style="margin: 10px;">
    <div class="col-6">
      <div class="count-of-items-pagination">
        Showing <span>{{ showingCountComputed }}</span> to <span>{{ showingToCountComputed }}</span> of <span>{{ totalItemsCountComputed }}</span>
      </div>
    </div>
    <div class="col-6">
      <nav class="pagination" aria-label="Pagination">
        <ul class="pagination">
          <li class="page-item" :class="{disabled: page === 1}">
            <a class="page-link" href="#" @click="changePage(--page)"><i class="fas fa-angle-double-left"></i> Previous</a>
          </li>

          <li v-show="!lastPages.includes(page - lastPages.length) && page !== 1 && pagesLimit !== 2" class="page-item"> <!-- um # lastPages verringert -->
            <a class="page-link" href="#" @click="changePage(1)">1</a>
          </li>

          <li v-show="pagesLimit > 1 && previousPages.includes(page - 2) && page !== 1 && page !== 2  && page !== 3 && page !== 4" class="page-item disabled">
            <a class="page-link" href="#">...</a>
          </li>

          <li v-for="previousPageNumber in previousPages" class="page-item" v-show="!lastPages.includes(previousPageNumber)">
            <a class="page-link" href="#" @click="changePage(previousPageNumber)">{{ previousPageNumber }}</a>
          </li>

          <li v-show="!lastPages.includes(page)" class="page-item active disabled">
            <a class="page-link" href="#">{{ page }}</a>
          </li>

          <li v-for="nextPageNumber in nextPages" class="page-item" v-show="!lastPages.includes(nextPageNumber - 1)">
            <a class="page-link" href="#" @click="changePage(nextPageNumber)">{{ nextPageNumber }}</a>
          </li>

          <li v-show="pagesLimit > 1 && !lastPages.includes(page + nextPages.length) && page !== (pagesLimit - 1) && page !== pagesLimit" class="page-item disabled"> <!-- um # nextPages erhöht -->
            <a class="page-link" href="#">...</a>
          </li>

          <li v-for="lastPageNumber in lastPages" :class="[lastPageNumber === page ? 'active disabled' : '']" class="page-item" v-show="lastPages.includes(page)">
            <a class="page-link" href="#" @click="changePage(lastPageNumber)">{{ lastPageNumber }}</a>
          </li>

          <li v-show="!lastPages.includes(page)" class="page-item">
            <a class="page-link" href="#" @click="changePage(pagesLimit)">{{ pagesLimit }}</a>
          </li>

          <li class="page-item" :class="{disabled: page >= pagesLimit}">
            <a class="page-link" href="#" @click="changePage(++page)">Next <i class="fas fa-angle-double-right"></i></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      items: this.itemsProp || [],
      limit: this.limitProp || 0,
      page: this.pageProp || 1,
      showingCount: this.showingCountProp || undefined,
      showingToCount: this.showingToCountProp || undefined,
      totalItemsCount: this.totalItemsCountProp || undefined,
    };
  },
  props: ['itemsProp', 'limitProp', 'pageProp', 'showingCountProp', 'showingToCountProp', 'totalItemsCountProp', 'paginationInfo'],
  methods: {
    changePage: function (page) {
      this.page = page;
      this.$emit('change-page', page);
    }
  },
  computed: {
    previousPages: function () {
      const countOfItems = 2;
      const previousPages = [...Array(countOfItems).keys()].map(i => this.page - i - 1);
      previousPages.filter(i => i <= 1).forEach(i => previousPages.splice(previousPages.indexOf(i), 1));
      return previousPages.sort();
    },
    nextPages: function () {
      const countOfItems = 2;
      const nextPages = [...Array(countOfItems).keys()].map(i => this.page + i + 1);
      nextPages.filter(i => i >= this.pagesLimit).forEach(i => nextPages.splice(nextPages.indexOf(i), 1));
      return nextPages;
    },
    lastPages: function () {
      const countOfItems = 2;
      const lastPages = [...Array(countOfItems).keys()].map(i => this.pagesLimit - i);
      lastPages.filter(i => i < 1).forEach(i => lastPages.splice(lastPages.indexOf(i), 1));
      return lastPages.sort();
    },
    pagesLimit: function () {
      return Math.max(1, Math.ceil(this.totalItemsCountComputed / this.limit));
    },
    showingCountComputed: function () {
      if (this.showingCount !== undefined) {
        return this.showingCount;
      }

      const showingCount = this.showingToCountComputed - this.limit + 1;
      const limitToItems = this.limit * (this.page - 1);
      if (showingCount < limitToItems) {
        return limitToItems + 1;
      }
      return showingCount;
    },
    showingToCountComputed: function () {
      if (this.showingToCount !== undefined) {
        return this.showingToCount;
      }

      if (this.items.length < this.limit * this.page) {
        return this.items.length;
      }
      return this.limit * this.page;
    },
    totalItemsCountComputed: function () {
      if (this.totalItemsCount !== undefined) {
        return this.totalItemsCount;
      }

      return this.items.length;
    }
  },
  watch: {
    paginationInfo: function (newData) {
      Object.keys(newData).forEach(key => this[key] = newData[key]);
    },
    itemsProp: function (newVal) {
      this.items = newVal;
    },
    pageProp: function (newVal) {
      this.page = newVal;
    }
  }
}
</script>

<style scoped>

</style>