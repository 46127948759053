<template>
    <div>
        <div class="modal fade" id="delete">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                      <span class="modal-title">Delete bid-sheet?</span>
                    </div>
                    <div class="modal-body popup-body">
                      <span class="text-center">Are you sure you want to DELETE the bid-sheet?</span>
                    </div>
                    <div class="popup-buttons">
                      <form :action="deleteLink" method="post">
                        <button type="submit" class="popup-confirm">Delete bid</button>
                      </form>
                      <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
              </div>
          </div>
        </div>
        <button class="buttons btn-danger" data-toggle="modal" data-target="#delete">Delete bid-sheet</button>
    </div>
</template>

<script>
    export default {
        name: "DeleteBid",
        methods: {
        },
        props: ['deleteLink']
    }
</script>
