<template>
    <div>
        <div class="modal fade" id="invoice-complete-po">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Invoicing PO</span>
                    </div>
                    <div class="modal-body popup-body">
                        <p>
                            Is the invoice for this PO complete? Further editing will not be possible.
                        </p>
                    </div>
                    <div class="popup-buttons" v-if="isFilled">
                        <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Yes, invoice complete</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Abort</button>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="isFilled" class="buttons" data-toggle="modal" data-target="#invoice-complete-po" :disabled="! isUnblocked">Invoice Complete</button>
        <button v-else class="buttons" :disabled="! isUnblocked" @click="abort">Invoice Complete</button>
    </div>
</template>

<script>
  export default {
      name: "InvoiceCompletePo",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          },
          abort: function () {
              this.$emit('aborted');
          },
      },
      props:['is-unblocked', 'is-filled'],
  }
</script>
