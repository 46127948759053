<template>
  <div>
    <button class="buttons" :disabled="! isDirty" @click="confirm">Mark items as deleted/undeleted</button>
  </div>
</template>

<script>
export default {
  name: "MarkAsDeleted",
  methods: {
    confirm: function () {
      this.$emit('confirmed');
    }
  },
  props:['is-dirty'],
}
</script>

<style scoped>

</style>