<template>
    <div id="vue-flashbag-container">
      <div v-for="message in success" class="alert alert-success alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {{ message }}
      </div>

      <div v-for="message in error" class="alert alert-danger alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {{ message }}
      </div>

      <div v-for="message in info" class="alert alert-info alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {{ message }}
      </div>
    </div>
</template>

<script>
  export default {
    name: "VueFlashbag",
    props: {
      success: Array,
      error: Array,
      info: Array,
    },
    computed: {
    },
    mounted() {

    }
  }
</script>
