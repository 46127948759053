const axios = require('axios').default;

if(document.querySelector('#vendor_table')) {
    document.appVendorTable = new Vue({
        el: '#vendor_table',
        name: "Vendor Table",
        data: {
            vendors: [],
            isSubmitInProgress: false,
            btnSave: 'Save',
            filterVendorName: '',
            filterVendorCode: '',
        },
        methods: {
            addVendor: function(emailArray) {
                emailArray.push('');
            },
            removeVendor: function(emailArray, index) {
                emailArray.splice(index, 1);
            },
            save: function (vendor) {
                vendor.email_cc = this.removeInvalidEmails(vendor.email_cc);
                vendor.email_reply_to = this.removeInvalidEmails(vendor.email_reply_to);
                vendor.email_to = this.removeInvalidEmails(vendor.email_to);
                let url = '/settings/vendors/' + vendor.code + '/save';
                let self=this;
                this.isSubmitInProgress = true;
                this.btnSave = 'Saving'
                axios.post(url, vendor)
                    .then(function (response) {
                        self.isSubmitInProgress = false;
                        self.btnSave = 'Save'
                        //console.log(response);
                    })
                    .catch(function (error) {
                        alert("There has been an error while saving.")
                        console.log(error);
                    });
            },
            removeInvalidEmails: function (emailsArray) {
                let pattern = /^\S+@\S+\.\S+/;
                for (let i = 0; i < emailsArray.length; i++) {
                    if (emailsArray[i] === "" || ! (emailsArray[i].match(pattern))) {
                        emailsArray.splice(i,1);
                    }
                }
                return emailsArray;
            }
        },
        computed: {
            vendorsFiltered: function() {
                let filtered = this.vendors;
                if (this.filterVendorName.length > 0) {
                    filtered = filtered.filter(item => {
                        return item.name.toLowerCase().indexOf(this.filterVendorName.toLowerCase()) > -1;
                    });
                }
                if (this.filterVendorCode.length > 0) {
                    filtered = filtered.filter(item => {
                        return item.code.toLowerCase().indexOf(this.filterVendorCode.toLowerCase()) > -1;
                    });
                }
                return filtered;
            }
        },
        mounted: function () {
            let self=this;
            axios.get('/settings/vendors/json')
                .then(function (response) {
                    // handle success
                    //console.log(response);
                    self.vendors=response.data;

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        delimiters: ["<%", "%>"]
    })
}
