<template>
    <i class="fas" :class="sorterIconClass" style="font-size: small" @click="toggle()"></i>
</template>

<script>
const states = [null, 'asc', 'desc'];

export default {
    name: "Sorter",
    data() {
        return {
          value: null,
        }
    },
    methods: {
        toggle: function () {
            switch (this.value) {
                case null:
                    this.value = 'asc';
                    break;
                case 'asc':
                    this.value = 'desc';
                    break;
                case 'desc':
                    this.value = null;
                    break;
            }

            this.$emit('input', this.value);
        }
    },
    computed: {
        sorterIconClass: function () {
            switch (this.value) {
                case null:
                    return 'fa-sort';
                case 'asc':
                    return 'fa-sort-up';
                case 'desc':
                    return 'fa-sort-down';
            }
        },
    },
}
</script>
