const axios = require('axios').default;
import OrderTypeEdit from "../components/OrderTypeEdit";
import VueFlashbag from '../components/VueFlashbag';
import DeleteOrderType from "../components/DeleteOrderType";

if (document.querySelector('.vue-order-type-edit')) {
    Vue.component('order-type-edit', OrderTypeEdit);
    Vue.component('vue-flashbag', VueFlashbag);
    Vue.component('delete-order-type', DeleteOrderType)

    document.vueOrderTypeEdit = new Vue({
        el: '.vue-order-type-edit',
        name: 'Order Type Create and Edit',
        data: {
            spinner: false,
            flashbag: {
                success: [],
                error: [],
                info: [],
            },
        },
        methods: {
            save: function() {
                this.spinner = true;
                let orderTypeSettings = this.$refs['order-type-edit'].$data.orderType;
                //console.debug(orderTypeSettings);
                axios.post('/order-type/save', orderTypeSettings)
                    .then(this.saveSuccess)
                    .catch(this.saveError)
                    .finally(this.saveFinally);
            },
            saveSuccess: function(response) {
                this.flash('success', "Successfully saved order type.");
                this.$refs['order-type-edit'].$data.orderType = response.data.data;
            },
            saveError: function(error) {
                console.debug(error);
                let message = 'There has been an error while saving the changes. Please check the validation errors and try again.';
                if (error.response && error.response.data && error.response.data.errors) {
                    message += `\n`;
                    for (let key of Object.keys(error.response.data.errors)) {
                        let errorMessage = error.response.data.errors[key];

                        message += `\n${key}: ${errorMessage}`;
                    }
                }
                this.flash('error', message);
            },
            saveFinally: function() {
                this.spinner = false;
            },
            flash: function(type, message) {
                let bag = this.flashbag[type];
                bag.push(message);
            }
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        delimiters: ["<%", "%>"]
    });
}
