const axios = require("axios").default;

import SaveChanges from "../components/SaveChanges";
import CancelPoItems from "../components/CancelPoItems";
import UnCancelPoItems from "../components/UnCancelPoItems";
import ConfirmPo from "../components/ConfirmPo";
import ConsolidatePo from "../components/ConsolidatePo";
import InvoiceCompletePo from "../components/InvoiceCompletePo";
import Sorter from "../components/Sorter";
import Pagination from "../components/Pagination";
import VueFlashbag from "../components/VueFlashbag";

if (document.querySelector(".vue-po-details")) {
	Vue.component("sorter", Sorter);
	Vue.component("pagination", Pagination);
	Vue.component("save-changes", SaveChanges);
	Vue.component("cancel-po-items", CancelPoItems);
	Vue.component("uncancel-po-items", UnCancelPoItems);
	Vue.component("confirm-po", ConfirmPo);
	Vue.component("consolidate-po", ConsolidatePo);
	Vue.component("invoice-complete-po", InvoiceCompletePo);
	Vue.component("vue-flashbag", VueFlashbag);

	const STATUS_READY_FOR_CONFIRMATION = 10;
	const STATUS_CANCELED = 20;
	const STATUS_CONFIRMED = 30;
	const STATUS_READY_FOR_CONSOLIDATION = 40;
	const STATUS_READY_FOR_INVOICE = 51;
	const STATUS_CLOSED = 60;

	Number.prototype.countDecimals = function() {
		if (Math.floor(this.valueOf()) === this.valueOf() && this !== "") return 0;
		return this.toString().split(".")[1].length || 0;
	};

	document.appPoDetails = new Vue({
		el: ".vue-po-details",
		name: "po details",
		data: {
			po: {},
			poErrors: undefined,
			isDirty: false,
			hasBeenSavedOnce: false,
			isFilled: false,
			isProcessing: false,
			spinner: false,
			selectedPos: [],
			flashbag: {
				success: [],
				error: [],
				info: [],
			},
			availableDeposits: [],
			availableSubstitutions: [],
			busyItems: [],
			substitutedItems: [],
			busyItemsDeposit: [],
			filterCPN: "",
			filterVPN: "",
			filterProductName: "",
			isShowOnlyErrors: false,
			singlePricesDecimal: 0,
			totalPricesDecimal: 0,
			page: 1,
			limit: 50,
			downloadTimeout: undefined,
      LoadErrorCounterDeposits: 0,
      LoadErrorCounterSubstitutes: 0,
		},
		mounted: function() {
			this.po = JSON.parse(
				Buffer.from(this.$refs.poJson.dataset.poJson, "base64")
			);
			this.singlePricesDecimal = parseInt(
				this.$refs.singlePricesDecimal.dataset.singlePricesDecimal
			);
			this.totalPricesDecimal = parseInt(
				this.$refs.totalPricesDecimal.dataset.totalPricesDecimal
			);

			this.getAvailableSubstitutions();
			this.getAvailableDeposits();
			this.setTakenSubstitutionsAndDepositsItems();
			this.setCancelItems();
			this.checkIfHasBeenSavedOnceAndIsFilledCompletly();
			this.runValidation();
		},
		methods: {
			// itemQuantity: function(item) { //Blendet die Ordered Quantity aus im TwigFile
			//     let quantity = this.toFixed(item.quantity, 2);
			//     if (item.substitutedProductVendorProductNumber != null) {
			//         quantity = null;
			//     }
			//     for (let key of Object.keys(this.availableDeposits)) {
			//         if (key == item.vendorProductNumber) {
			//             quantity = null;
			//         }
			//     }
			//     return quantity;
			// },
			checkIfHasBeenSavedOnceAndIsFilledCompletly: function() {
				if (this.isConfirmation) {
					let completed = 0;
					this.po.items.forEach((item) => {
						if (
							item.confirmedQuantity !== null ||
							item.confirmedPrice !== null
						) {
							this.hasBeenSavedOnce = true;
						}
						if (
							item.confirmedQuantity !== null &&
							item.confirmedPrice !== null
						) {
							completed++;
						}
					});
					if (completed == this.po.items.length) {
						this.isFilled = true;
					}
				}
				if (this.isConsolidation) {
					let completed = 0;
					this.po.items.forEach((item) => {
						if (
							item.consolidationQuantity !== null &&
							item.consolidationQuantity !== "0.00"
						) {
							this.hasBeenSavedOnce = true;
						}
						if (item.consolidationQuantity !== null) {
							completed++;
						}
					});
					if (completed == this.po.items.length) {
						this.isFilled = true;
					}
				}
				if (this.isInvoicing) {
					let completed = 0;
					this.po.items.forEach((item) => {
						if (
							(item.invoiceQuantity !== null &&
								item.invoiceQuantity !== "0.00") ||
							(item.invoicePrice !== null && item.invoicePrice !== "0.00")
						) {
							this.hasBeenSavedOnce = true;
						}
						if (item.invoiceQuantity !== null && item.invoicePrice !== null) {
							completed++;
						}
					});
					if (completed == this.po.items.length) {
						this.isFilled = true;
					}
				}
			},
			addSubstAndDeposit: function() {
				this.po.items.forEach((item) => {
					if (item.substitutedProductVendorProductNumber != null) {
						item.customerProductNumber = item.customerProductNumber + "*subst*";
					}
					Object.values(this.availableDeposits).forEach((val) => {
						if (item.vendorProductNumber === val.vendor_product_number) {
							item.customerProductNumber =
								item.customerProductNumber + "*deposit*";
						}
					});
				});
				this.runValidation();
			},
			isItemTaken: function(item, SubstOrDeposit) {
				if (SubstOrDeposit == "SUBST") {
					return this.busyItems.includes(
						item.product_name_number + "*subst*_" + item.vendor_product_number
					);
				} else {
					return this.busyItemsDeposit.includes(
						item.product_name_number + "*deposit*_" + item.vendor_product_number
					);
				}
			},
			onChanged: function(item) {
				this.isDirty = true;
				if (this.isConfirmation) {
					if (
						item.confirmedQuantity != null &&
						item.confirmedQuantity.includes("⁠")
					) {
						item.confirmedQuantity = item.confirmedQuantity.replace("⁠", "");
					}
					if (
						item.confirmedPrice != null &&
						item.confirmedPrice.includes("⁠")
					) {
						item.confirmedPrice = item.confirmedPrice.replace("⁠", "");
					}
				}
				if (this.isConsolidation) {
					if (
						item.consolidationQuantity != null &&
						item.consolidationQuantity.includes("⁠")
					) {
						item.consolidationQuantity = item.consolidationQuantity.replace(
							"⁠",
							""
						);
					}
				}
				if (this.isInvoicing) {
					if (
						item.invoiceQuantity != null &&
						item.invoiceQuantity.includes("⁠")
					) {
						item.invoiceQuantity = item.invoiceQuantity.replace("⁠", "");
					}
					if (item.invoicePrice != null && item.invoicePrice.includes("⁠")) {
						item.invoicePrice = item.invoicePrice.replace("⁠", "");
					}
				}
				this.runValidation();
			},
			onChangedHeader: function() {
				this.isDirty = true;
				this.runValidation();
			},
			onChangedWithoutValidation: function() {
				this.isDirty = true;
			},
			runValidation: function() {
				axios
					.post("/po/" + this.po.id + "/validate", this.po)
					.then(this.runValidationSuccess)
					.catch(this.runValidationError)
					.finally(this.runValidationFinally);
			},
			runValidationSuccess: function(response) {
				//console.debug(response);
				this.poErrors = response.data;
			},
			runValidationError: function(error) {
				//console.debug(error);
			},
			runValidationFinally: function() {},
			setTakenSubstitutionsAndDepositsItems: function() {
				this.po.items.forEach((item) => {
					if (item.substitutedProductVendorProductNumber !== null) {
						this.busyItems.push(
							item.customerProductNumber + "*subst*_" + item.vendorProductNumber
						);
						this.substitutedItems.push(
							item.substitutedProductVendorProductNumber
						);
					}
				});
				this.po.items.forEach((item) => {
					this.busyItemsDeposit.push(
						item.customerProductNumber + "*deposit*_" + item.vendorProductNumber
					);
					if (item.depositCustomerProductNumber !== null) {
						this.substitutedItems.push(
							item.depositCustomerProductNumber + "*deposit*"
						);
					}
				});
			},

			getItemFieldError(item, fieldName) {
				let error = this.getItemError(item);
				if (!error) {
					return undefined;
				}

				if (error[fieldName]) {
					return error[fieldName];
				}

				return undefined;
			},
			getItemError(item) {
				if (
					item.customerProductNumber &&
					item.vendorProductNumber &&
					item.substitutedProductVendorProductNumber &&
					this.poErrors &&
					this.poErrors[item.customerProductNumber] &&
					this.poErrors[item.customerProductNumber][item.vendorProductNumber] &&
					this.poErrors[item.customerProductNumber][item.vendorProductNumber][
						item.substitutedProductVendorProductNumber
					]
				) {
					return this.poErrors[item.customerProductNumber][
						item.vendorProductNumber
					][item.substitutedProductVendorProductNumber];
				} else if (
					item.customerProductNumber &&
					item.vendorProductNumber &&
					item.substitutedProductVendorProductNumber == null &&
					this.poErrors &&
					this.poErrors[item.customerProductNumber] &&
					this.poErrors[item.customerProductNumber][item.vendorProductNumber] &&
					this.poErrors[item.customerProductNumber][item.vendorProductNumber][
						"Error"
					]
				) {
					return this.poErrors[item.customerProductNumber][
						item.vendorProductNumber
					]["Error"];
				}
				return undefined;
			},
			save: function() {
				this.spinner = true;
				this.po.items.forEach((item) => {
					if (item.customerProductNumber.includes("*subst*")) {
						item.customerProductNumber = item.customerProductNumber.replace(
							"*subst*",
							""
						);
					}
					if (item.customerProductNumber.includes("*deposit*")) {
						item.customerProductNumber = item.customerProductNumber.replace(
							"*deposit*",
							""
						);
					}
					if (this.isConfirmation) {
						if (
							item.confirmedQuantity != null &&
							item.confirmedQuantity.includes("⁠")
						) {
							item.confirmedQuantity = item.confirmedQuantity.replace("⁠", "");
						}
						if (
							item.confirmedPrice != null &&
							item.confirmedPrice.includes("⁠")
						) {
							item.confirmedPrice = item.confirmedPrice.replace("⁠", "");
						}
					}
					if (this.isConsolidation) {
						if (
							item.consolidationQuantity != null &&
							item.consolidationQuantity.includes("⁠")
						) {
							item.consolidationQuantity = item.consolidationQuantity.replace(
								"⁠",
								""
							);
						}
					}
					if (this.isInvoicing) {
						if (
							item.invoiceQuantity != null &&
							item.invoiceQuantity.includes("⁠")
						) {
							item.invoiceQuantity = item.invoiceQuantity.replace("⁠", "");
						}
						if (item.invoicePrice != null && item.invoicePrice.includes("⁠")) {
							item.invoicePrice = item.invoicePrice.replace("⁠", "");
						}
					}
				});
				axios
					.post("/po/" + this.po.id + "/edit", this.po)
					.then(this.saveSuccess)
					.catch(this.saveError)
					.finally(this.saveFinally);
			},
			saveSuccess: function(response) {
				//console.debug(response);
				this.isDirty = false;

				// reload page so all data reloads from database
				this.isProcessing = true;
				window.location.reload(true);
			},
			saveError: function(error) {
				let message = "There has been an error while saving the changes: ";
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				this.flashbag.error.push(message);
				this.runValidation();
			},
			saveFinally: function() {
				this.spinner = false;
			},
			startXLSXDownload: function() {
				if (this.isDirty) {
					return;
				} else {
					this.spinner = true;
					this.setCookie("downloadStarted", 0, 100); //Expiration could be anything... As long as we reset the value
					setTimeout(this.checkDownloadCookie, 1000); //Initiate the loop to check the cookie.
				}
			},
			setCookie: function(name, value, expiracy) {
				var exdate = new Date();
				exdate.setTime(exdate.getTime() + expiracy * 1000);
				var c_value =
					escape(value) +
					(expiracy == null ? "" : "; expires=" + exdate.toUTCString());
				document.cookie = name + "=" + c_value + "; path=/";
			},
			getCookie: function(name) {
				var i,
					x,
					y,
					ARRcookies = document.cookie.split(";");
				for (i = 0; i < ARRcookies.length; i++) {
					x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
					y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
					x = x.replace(/^\s+|\s+$/g, "");
					if (x == name) {
						return y ? decodeURI(unescape(y.replace(/\+/g, " "))) : y; //;//unescape(decodeURI(y));
					}
				}
			},
			checkDownloadCookie: function() {
				if (this.getCookie("downloadStarted") == 1) {
					this.setCookie("downloadStarted", "false", 100); //Expiration could be anything... As long as we reset the value
					this.spinner = false;
				} else {
					this.downloadTimeout = setTimeout(this.checkDownloadCookie, 1000); //Re-run this function in 1 second.
				}
			},
			updateViaFilledXlsx: function() {
				if (this.$refs.updateViaFilledXlsx.files.length === 0) {
					return;
				}
				this.spinner = true;
				let formData = new FormData();
				formData.append("file", this.$refs.updateViaFilledXlsx.files[0]);

				axios
					.post(
						this.$refs.updateViaFilledXlsx.getAttribute("data-path"),
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then(this.updateViaFilledXlsxSuccess)
					.catch(this.updateViaFilledXlsxError)
					.finally(this.updateViaFilledXlsxFinally);
			},
			updateViaFilledXlsxSuccess: function(response) {
				window.location.reload(true);
			},
			updateViaFilledXlsxError: function(error) {
				let message = "There has been an error while uploading the sheet: ";
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				this.flashbag.error.push(message);
			},
			updateViaFilledXlsxFinally: function() {
				this.$refs.updateViaFilledXlsx.value = "";
				this.spinner = false;
			},
			copyToConfirmed(item) {
				if (item.isDisabled == false) {
					if (
						item.customerProductNumber.includes("*deposit*") ||
						item.customerProductNumber.includes("*subst*")
					) {
						item.confirmedQuantity = "";
					} else {
						item.confirmedQuantity = item.quantity;
					}
					item.confirmedPrice = item.unitPrice;
				}
				this.onChanged(item);
			},
			copyToConfirmedForAll(item) {
				if (item.isDisabled == false) {
					if (
						item.customerProductNumber.includes("*deposit*") ||
						item.customerProductNumber.includes("*subst*")
					) {
						item.confirmedQuantity = "";
					} else {
						item.confirmedQuantity = item.quantity;
					}
					item.confirmedPrice = item.unitPrice;
				}
				this.onChangedWithoutValidation();
			},
			copyToConfirmedAll() {
				for (let key of Object.keys(this.po.items)) {
					this.copyToConfirmedForAll(this.po.items[key]);
				}
				this.onChangedHeader();
			},
			copyToConsolidated(item) {
				if (item.isDisabled == false) {
					item.consolidationQuantity = item.confirmedQuantity;
					this.onChanged(item);
				}
			},
			copyToConsolidatedForAll(item) {
				if (item.isDisabled == false) {
					item.consolidationQuantity = item.confirmedQuantity;
					this.onChangedWithoutValidation();
				}
			},
			copyToConsolidatedAll() {
				for (let key of Object.keys(this.po.items)) {
					this.copyToConsolidatedForAll(this.po.items[key]);
				}
				this.onChangedHeader();
			},
			copyToInvoiced(item) {
				if (item.isDisabled == false) {
					item.invoiceQuantity = item.consolidationQuantity;
					if (item.confirmedPrice == null || item.confirmedPrice == "") {
						item.invoicePrice = item.unitPrice;
					} else {
						item.invoicePrice = item.confirmedPrice;
					}
					this.onChanged(item);
				}
			},
			copyToInvoicedForAll(item) {
				if (item.isDisabled == false) {
					item.invoiceQuantity = item.consolidationQuantity;
					if (item.confirmedPrice == null || item.confirmedPrice == "") {
						item.invoicePrice = item.unitPrice;
					} else {
						item.invoicePrice = item.confirmedPrice;
					}
					this.onChangedWithoutValidation();
				}
			},
			copyToInvoicedAll() {
				for (let key of Object.keys(this.po.items)) {
					this.copyToInvoicedForAll(this.po.items[key]);
				}
				this.onChangedHeader();
			},
			cancelItems: function() {
				for (let key of Object.keys(this.po.items)) {
					if (this.selectedPos.includes(this.po.items[key].id)) {
						if (this.isConfirmation) {
							this.po.items[key].confirmedQuantity = "0.00";
							if (
								this.po.items[key].confirmedPrice == null ||
								this.po.items[key].confirmedPrice == ""
							) {
								this.po.items[key].confirmedPrice = this.po.items[
									key
								].unitPrice;
							}
						} else if (this.isConsolidation) {
							this.po.items[key].consolidationQuantity = "0.00";
							this.po.items[key].invoiceQuantity = this.po.items[
								key
							].consolidationQuantity;
							this.po.items[key].invoicePrice = this.po.items[
								key
							].confirmedPrice;
						}
						this.po.items[key].isDisabled = true;
					}
				}
				// this.selectedPos.splice(0, this.selectedPos.length); //unselect all items
				this.onChangedHeader();
			},
			cancelItem: function(id) {
				for (let key of Object.keys(this.po.items)) {
					if (this.po.items[key].id == id) {
						if (this.isConfirmation) {
							this.po.items[key].confirmedQuantity = "0.00";
							if (
								this.po.items[key].confirmedPrice == null ||
								this.po.items[key].confirmedPrice == "" ||
								this.po.items[key].confirmedPrice == "⁠"
							) {
								this.po.items[key].confirmedPrice = this.po.items[
									key
								].unitPrice;
							}
						} else if (this.isConsolidation) {
							this.po.items[key].consolidationQuantity = "0.00";
							this.po.items[key].invoiceQuantity = this.po.items[
								key
							].consolidationQuantity;
							this.po.items[key].invoicePrice = this.po.items[
								key
							].confirmedPrice;
						} else if (this.isInvoicing) {
							this.po.items[key].invoiceQuantity = "0.00";
							if (
								this.po.items[key].confirmedPrice == null ||
								this.po.items[key].confirmedPrice == "" ||
								this.po.items[key].confirmedPrice == "⁠"
							) {
								this.po.items[key].invoicePrice = this.po.items[key].unitPrice;
							} else {
								this.po.items[key].invoicePrice = this.po.items[
									key
								].confirmedPrice;
							}
						}
						this.po.items[key].isDisabled = true;
					}
				}
				this.onChangedHeader();
			},
			unCancelItems: function() {
				for (let key of Object.keys(this.po.items)) {
					if (
						this.selectedPos.includes(this.po.items[key].id) &&
						this.po.items[key].isDisabled === true
					) {
						if (this.isConfirmation) {
							this.po.items[key].confirmedQuantity = "";
						} else if (this.isConsolidation) {
							this.po.items[key].consolidationQuantity = "";
							this.po.items[key].invoiceQuantity = null;
							this.po.items[key].invoicePrice = null;
						}
						this.po.items[key].isDisabled = false;
					}
				}
				this.selectedPos.splice(0, this.selectedPos.length); //unselect all items
				this.onChangedHeader();
			},
			unCancelItem: function(id) {
				for (let key of Object.keys(this.po.items)) {
					if (this.po.items[key].id == id) {
						if (this.isConfirmation) {
							this.po.items[key].confirmedQuantity = "";
						} else if (this.isConsolidation) {
							this.po.items[key].consolidationQuantity = "";
							this.po.items[key].invoiceQuantity = null;
							this.po.items[key].invoicePrice = null;
						}
						this.po.items[key].isDisabled = false;
					}
				}
				this.onChangedHeader();
			},
			setCancelItems: function() {
				let canceled = 0;
				for (let key of Object.keys(this.po.items)) {
					if (this.isInvoicing || this.isPastInvoicing) {
						if (
							this.po.items[key].consolidationQuantity === "0.00" &&
							this.po.items[key].confirmedQuantity === "0.00"
						) {
							this.po.items[key].invoiceQuantity = this.po.items[
								key
							].consolidationQuantity;
							this.po.items[key].invoicePrice = this.po.items[
								key
							].confirmedPrice;
							canceled++;
							this.po.items[key].isDisabled = true;
						} else if (this.po.items[key].invoiceQuantity === "0.00") {
							canceled++;
							this.po.items[key].isDisabled = true;
						}
					} else if (
						this.isConfirmation ||
						(this.isPastConfirmation &&
							!this.isConsolidation &&
							!this.isInvoicing)
					) {
						if (this.po.items[key].confirmedQuantity === "0.00") {
							canceled++;
							this.po.items[key].isDisabled = true;
						}
					} else if (this.isConsolidation) {
						if (
							(this.po.items[key].confirmedQuantity === "0.00" &&
								this.po.items[key].consolidationQuantity === null) ||
							this.po.items[key].consolidationQuantity === "0.00"
						) {
							this.po.items[key].consolidationQuantity = "0.00";
							canceled++;
							this.po.items[key].isDisabled = true;
						}
					}
				}
				if (canceled == this.po.items.length) {
					this.hasBeenSavedOnce = true;
				}
			},
			triggerShowErrors: function() {
				let error = 0;
				for (let key of Object.keys(this.po.items)) {
					if (this.isConfirmation) {
						if (
							this.po.items[key].confirmedQuantity === "" ||
							this.po.items[key].confirmedQuantity === null
						) {
							this.po.items[key].confirmedQuantity = "⁠";
							error++;
						}
						if (
							this.po.items[key].confirmedPrice === "" ||
							this.po.items[key].confirmedPrice === null
						) {
							this.po.items[key].confirmedPrice = "⁠";
							error++;
						}
					} else if (this.isConsolidation) {
						if (
							this.po.items[key].confirmedQuantity === "" ||
							this.po.items[key].consolidationQuantity === null
						) {
							this.po.items[key].consolidationQuantity = "⁠";
							error++;
						}
					} else if (this.isInvoicing) {
						if (
							this.po.items[key].invoiceQuantity === "" ||
							this.po.items[key].invoiceQuantity === null
						) {
							this.po.items[key].invoiceQuantity = "⁠";
							error++;
						}
						if (
							this.po.items[key].invoicePrice === "" ||
							this.po.items[key].invoicePrice === null
						) {
							this.po.items[key].invoicePrice = "⁠";
							error++;
						}
					}
				}
				this.runValidation();
				this.isShowOnlyErrors = true;
				if (error > 0) {
					let message =
						"There are empty fields whithin the Purchase Order. Please check and fill those fields with values.";
					this.flashbag.error.push(message);
				}
			},
			confirm: function() {
				this.isProcessing = true;
				window.location.href = "/po/" + this.po.id + "/confirm";
			},
			consolidate: function(isComplete) {
				this.isProcessing = true;
				window.location.href =
					"/po/" +
					this.po.id +
					"/consolidate" +
					(isComplete ? "?complete=1" : "");
			},
			completeInvoice: function() {
				this.spinner = true;
				let x = 0;
				this.po.items.forEach((item) => {
					if (item.invoiceQuantity != 0 && item.consolidationQuantity == 0) {
						this.flashbag.error.push(
							"There has been an error while completion the invoice: The invoice quantity for sea chefs product number " +
								item.customerProductNumber +
								" can only be zero. There is no delivery for this product."
						);
						x = 1;
					}
				});
				if (x == 1) {
					this.spinner = false;
					return;
				}
				if (
					this.po.invoiceNumber == null ||
					this.po.invoiceNumber.trim() == ""
				) {
					this.flashbag.error.push(
						"There has been an error while completion the invoice: Please enter an invoice number."
					);
					this.spinner = false;
					return;
				}
				if (this.po.invoiceDate == null) {
					this.flashbag.error.push(
						"There has been an error while completion the invoice: Please enter an invoice date."
					);
					this.spinner = false;
					return;
				}
				this.isProcessing = true;
				window.location.href = "/po/" + this.po.id + "/complete-invoice";
			},
			addDeposit: function(item) {
				let index = 0;
				for (let indexLoop = 0; indexLoop < this.po.items.length; indexLoop++) {
					const element = this.po.items[indexLoop];
					if (element.customerProductNumber == item.customerProductNumber) {
						index = indexLoop;
						break;
					}
				}
				this.substitutedItems.push(this.po.items[index].vendorProductNumber);
				let indexFiltered = (this.page - 1) * this.limit + index;
				this.po.items.splice(parseInt(indexFiltered) + 1, 0, {
					isDeposit: true,
					parent: this.po.items[indexFiltered],
					depositCustomerProductNumber: this.po.items[indexFiltered]
						.customerProductNumber,
					addTime: Date.now(),
				});
			},
			chooseDeposit: function(item) {
				let selectedDeposit = this.availableDeposits[item.selectedDeposit];
				this.busyItemsDeposit.push(
					selectedDeposit.product_name_number +
						"*deposit*_" +
						selectedDeposit.vendor_product_number
				);

				this.$set(
					item,
					"customerProductNumber",
					selectedDeposit.product_name_number + "*deposit*"
				);
				this.$set(
					item,
					"vendorProductNumber",
					selectedDeposit.vendor_product_number
				);
				this.$set(item, "summary", selectedDeposit.product_name);
				this.$set(item, "vendorUnit", selectedDeposit.vendor_package_type);
				this.$set(item, "quantity", 0);
				this.$set(item, "unitPrice", selectedDeposit.bid_price);
				this.$set(item, "total", 0);
				this.$set(item, "category", item.parent.category);
				this.$set(item, "subCategory", item.parent.subCategory);
				this.$set(item, "microCategory", item.parent.microCategory);
				this.$set(item, "confirmedQuantity", "");
				this.$set(item, "confirmedPrice", "");
				this.$set(item, "consolidationQuantity", null);
				this.$set(item, "allowsDeposit", false);
				this.$set(item, "allowsSubstitution", false);
				this.$set(item, "parent", null);
				this.$set(item, "isDisabled", false);

				this.onChangedHeader();
			},
			addSubstitution: function(item) {
				let index = 0;
				for (let indexLoop = 0; indexLoop < this.po.items.length; indexLoop++) {
					const element = this.po.items[indexLoop];
					if (element.customerProductNumber == item.customerProductNumber) {
						index = indexLoop;
						break;
					}
				}
				this.po.items[index].isSubstituted = true;
				this.substitutedItems.push(this.po.items[index].vendorProductNumber);
				let indexFiltered = index;
				this.po.items.splice(parseInt(indexFiltered) + 1, 0, {
					customerProductNumber: this.po.items[indexFiltered]
						.customerProductNumber,
					invoicePrice: null,
					invoiceQuantity: null,
					isSubstitution: true,
					parent: this.po.items[indexFiltered],
					substitutedProductVendorProductNumber: this.po.items[indexFiltered]
						.vendorProductNumber,
					addTime: Date.now(),
				});
			},
			removeRow: function(index, item) {
				this.substitutedItems.splice(
					this.substitutedItems.indexOf(
						item.substitutedProductVendorProductNumber
					),
					1
				);
				let key = this.busyItems.indexOf(
					item.customerProductNumber + "_" + item.vendorProductNumber
				);
				if (key >= 0) {
					this.busyItems.splice(key, 1);
				}
				key = this.busyItemsDeposit.indexOf(
					item.customerProductNumber + "_" + item.vendorProductNumber
				);
				if (key >= 0) {
					this.busyItemsDeposit.splice(key, 1);
				}
				let indexToDeleteInPoItems = this.po.items.findIndex(
					(element) => element.addTime === item.addTime
				);
				this.po.items.splice(indexToDeleteInPoItems, 1);
				this.onChangedHeader();
			},
			chooseSubstitution: function(item) {
				let selectedSubstitution = this.availableSubstitutions[
					item.parent.customerProductNumber
				][item.selectedSubstitution];
				this.busyItems.push(
					item.customerProductNumber +
						"*subst*_" +
						selectedSubstitution.vendor_product_number
				);

				this.$set(
					item,
					"customerProductNumber",
					item.parent.customerProductNumber + "*subst*"
				);
				this.$set(
					item,
					"vendorProductNumber",
					selectedSubstitution.vendor_product_number
				);
				this.$set(item, "summary", selectedSubstitution.vendor_product_name);
				this.$set(item, "vendorUnit", selectedSubstitution.vendor_package_type);
				this.$set(item, "quantity", 0);
				this.$set(item, "unitPrice", selectedSubstitution.bid_price);
				this.$set(item, "total", 0);
				this.$set(item, "category", item.parent.category);
				this.$set(item, "subCategory", item.parent.subCategory);
				this.$set(item, "microCategory", item.parent.microCategory);
				this.$set(item, "confirmedQuantity", "");
				this.$set(item, "confirmedPrice", "");
				this.$set(item, "consolidationQuantity", null);
				this.$set(item, "allowsDeposit", false);
				this.$set(item, "allowsSubstitution", false);
				this.$set(item, "parent", null);
				this.$set(item, "isDisabled", false);

				this.onChangedHeader();
			},
			getAvailableDeposits: function() {
				this.po.items.forEach((item) => {
					item.allowsDeposit = false;
				});
				axios
					.get("/api/" + this.po.id + "/get-allow-deposit")
					.then((response) => {
						this.po.items.forEach((item) => {
							if (response.data.includes(item.customerProductNumber)) {
								item.allowsDeposit = true;
							}
						});
          })
          .catch(this.getAvailableDepositsError);
				axios
					.get("/po/" + this.po.id + "/deposits")
					.then(this.getAvailableDepositsSuccess)
					.catch(this.getAvailableDepositsError)
					.finally(this.getAvailableDepositsFinally);
			},
			getAvailableDepositsSuccess: function(response) {
				//console.debug(response);
				Object.values(response.data.deposits).forEach((deposit) => {
					deposit.bid_price = parseFloat(deposit.bid_price).toFixed(4);
				});
				this.availableDeposits = response.data.deposits;
				this.addSubstAndDeposit();
			},
			getAvailableDepositsError: function(error) {
        this.LoadErrorCounterDeposits++;
        console.debug(error);
        if (this.LoadErrorCounterDeposits >= 5) {
          this.flashbag.error.push(
            "There has been a problem while fetching some data. Please reload the purchase order or try again later. If the problem persists please contact seachefs!"
          )
        } else {
          setTimeout(this.getAvailableDeposits(), 1000)
        }
        // this.flashbag.error.push(
        // 	"Could not fetch the list of available deposits. Please reload the purchase order."
        // );
			},
			getAvailableDepositsFinally: function() {},
			getAvailableSubstitutions: function() {
				this.po.items.forEach((item) => {
					item.allowsSubstitution = false;
				});
				axios
					.get("/po/" + this.po.id + "/substitutions")
					.then(this.getAvailableSubstitutionsSuccess)
					.catch(this.getAvailableSubstitutionsError)
					.finally(this.getAvailableSubstitutionsFinally);
			},
			getAvailableSubstitutionsSuccess: function(response) {
				//console.debug(response);
				Object.values(response.data.substitutions).forEach((substitution) => {
					Object.values(substitution).forEach((singleSubstitution) => {
						singleSubstitution.bid_price = parseFloat(
							singleSubstitution.bid_price
						).toFixed(4);
						this.po.items.forEach((item) => {
							if (
								item.customerProductNumber ==
									singleSubstitution.product_name_number &&
								item.substitutedProductVendorProductNumber == null
							) {
								item.allowsSubstitution = true;
							}
						});
					});
				});
				this.availableSubstitutions = response.data.substitutions;
			},
			getAvailableSubstitutionsError: function(error) {
        this.LoadErrorCounterSubstitutes++;
        console.debug(error);
        if (this.LoadErrorCounterSubstitutes >= 5) {
          this.flashbag.error.push(
            "There has been a problem while fetching some data. Please reload the purchase order or try again later. If the problem persists please contact seachefs!"
          )
        } else {
          setTimeout(this.getAvailableSubstitutions(), 1000)
        }
        // this.flashbag.error.push(
        // 	"Could not fetch the list of available substitutions. Please reload the purchase order."
        // );
			},
			getAvailableSubstitutionsFinally: function() {},
			getCpnVpn: function(item) {
				return item.customerProductNumber + "-" + item.vendorProductNumber;
			},
			getCpnVpnSubst: function(item) {
				return (
					item.customerProductNumber +
					"-" +
					item.vendorProductNumber +
					"-" +
					item.substitutedProductVendorProductNumber
				);
			},
			isCanceled: function(item) {
				if (item.isDisabled) {
					return true;
				}
				return false;
			},
			isCanceledComplete: function(item) {
				if (item.isDisabled && this.isPastInvoicing) {
					return true;
				}
			},
			isSubstitution: function(item) {
				let x = 0;

				this.substitutedItems.forEach((element) => {
					if (element.indexOf("*deposit*") == -1) {
						if (item.vendorProductNumber == element) {
							x = 1;
						}
					} else {
						if (item.customerProductNumber + "*deposit*" == element) {
							x = 1;
						}
					}
				});
				if (x === 1) {
					return true;
				}
				if (item.substitutedProductVendorProductNumber != null) {
					return true;
				}
				if (item.depositCustomerProductNumber != null) {
					return true;
				}
			},
			isSubstitutionOrDepositFirstItem: function(item, index) {
				let x = 0;
				this.substitutedItems.forEach((element) => {
					if (element.indexOf("*deposit*") == -1) {
						if (item.vendorProductNumber == element) {
							x = 1;
						}
					} else {
						if (item.customerProductNumber + "*deposit*" == element) {
							x = 1;
						}
					}
				});
				if (
					x === 1 &&
					item.substitutedProductVendorProductNumber == null &&
					item.depositCustomerProductNumber == null
				) {
					return true;
				}
			},
			isSubstitutionOrDepositLastItem: function(item, index) {
				if (
					!this.filteredItems[(this.page - 1) * this.limit + index + 1] &&
					item.substitutedProductVendorProductNumber != null &&
					item.depositCustomerProductNumber != null
				) {
					return true;
				}
				if (
					item.substitutedProductVendorProductNumber != null &&
					!this.filteredItems[(this.page - 1) * this.limit + index + 1]
				) {
					return true;
				}
				if (
					item.depositCustomerProductNumber != null &&
					!this.filteredItems[(this.page - 1) * this.limit + index + 1]
				) {
					return true;
				}
				if (
					item.substitutedProductVendorProductNumber != null &&
					item.substitutedProductVendorProductNumber !=
						this.filteredItems[(this.page - 1) * this.limit + index + 1]
							.substitutedProductVendorProductNumber
				) {
					return true;
				}
				if (
					item.depositCustomerProductNumber != null &&
					item.depositCustomerProductNumber !=
						this.filteredItems[(this.page - 1) * this.limit + index + 1]
							.depositCustomerProductNumber
				) {
					return true;
				}
				return false;
			},
			toFixed: function(value, decimalNumber) {
				let parsedValue = parseFloat(value);
				if (isNaN(parsedValue)) {
					return "";
				}
				parsedValue =
					Math.round(parsedValue * Math.pow(10, decimalNumber)) /
					Math.pow(10, decimalNumber);
				return parsedValue.toFixed(decimalNumber);
			},
			confirmedTotal: function(item) {
				if (item.confirmedQuantity > 0 && item.confirmedPrice > 0) {
					let confirmedQuantity = parseFloat(item.confirmedQuantity);
					let confirmedPrice = parseFloat(item.confirmedPrice);
					let total = confirmedQuantity * confirmedPrice;
					let totalCountDigigts = total.toString().length;
					let totalString = total.toPrecision(
						totalCountDigigts - total.countDecimals() + this.totalPricesDecimal
					);
					total = parseFloat(totalString);
					total =
						Math.round(total * Math.pow(10, this.totalPricesDecimal)) /
						Math.pow(10, this.totalPricesDecimal);
					total = total.toFixed(this.totalPricesDecimal);
					return total;
				}
				return "0.00";
			},
			invoiceTotal: function(item) {
				if (item.invoiceQuantity > 0 && item.invoicePrice > 0) {
					let invoiceQuantity = parseFloat(item.invoiceQuantity);
					let invoicePrice = parseFloat(item.invoicePrice);
					let total = invoiceQuantity * invoicePrice;
					let totalCountDigigts = total.toString().length;
					let totalString = total.toPrecision(
						totalCountDigigts - total.countDecimals() + this.totalPricesDecimal
					);
					total = parseFloat(totalString);
					total =
						Math.round(total * Math.pow(10, this.totalPricesDecimal)) /
						Math.pow(10, this.totalPricesDecimal);
					total = total.toFixed(this.totalPricesDecimal);
					return total;
				}
				return "0.00";
			},
			onFilterInput: function() {
				this.page = 1;
				this.selectedPos = [];
			},
			changePage: function(page) {
				this.page = page;
			},
		},
		computed: {
      isShipTypeRE: function() {
        return this.po.shipType === 2 || this.po.shipType === 3;
      },
			isConfirmation: function() {
				return this.po.status === STATUS_READY_FOR_CONFIRMATION;
			},
			isPastConfirmation: function() {
				return [
					STATUS_CONFIRMED,
					STATUS_READY_FOR_CONSOLIDATION,
					STATUS_READY_FOR_INVOICE,
					STATUS_CANCELED,
					STATUS_CLOSED,
				].includes(this.po.status);
			},
			isConsolidation: function() {
				return this.po.status === STATUS_READY_FOR_CONSOLIDATION;
			},
			isPastConsolidation: function() {
				return [
					STATUS_READY_FOR_INVOICE,
					STATUS_CANCELED,
					STATUS_CLOSED,
				].includes(this.po.status);
			},
			isInvoicing: function() {
				return this.po.status === STATUS_READY_FOR_INVOICE;
			},
			isPastInvoicing: function() {
				return [STATUS_CANCELED, STATUS_CLOSED].includes(this.po.status);
			},
			itemsOrderedTotal: function() {
				let total = 0;
				if (this.po && this.po.items) {
					for (let key of Object.keys(this.po.items)) {
						if (this.po.items[key].total == null) {
							total += 0;
						} else {
							total += parseFloat(this.po.items[key].total);
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			itemsOrderedTotalFiltered: function() {
				let total = 0;
				if (this.po && this.filteredItems) {
					for (let key of Object.keys(this.filteredItems)) {
						if (this.filteredItems[key].total == null) {
							total += 0;
						} else {
							total += parseFloat(this.filteredItems[key].total);
						}
					}
				}
				return total;
			},
			itemsConfirmedTotal: function() {
				let total = 0;
				let confirmedPrice = 0;
				let confirmedQuantity = 0;

				if (this.po && this.po.items) {
					for (let key of Object.keys(this.po.items)) {
						if (
							this.po.items[key].confirmedQuantity > 0 &&
							this.po.items[key].confirmedPrice > 0
						) {
							confirmedQuantity = parseFloat(
								this.po.items[key].confirmedQuantity
							);
							confirmedPrice = parseFloat(this.po.items[key].confirmedPrice);
							let totalTemp = confirmedQuantity * confirmedPrice;
							let totalCountDigigts = totalTemp.toString().length;
							let totalString = totalTemp.toPrecision(
								totalCountDigigts -
									totalTemp.countDecimals() +
									this.totalPricesDecimal
							);
							totalTemp = parseFloat(totalString);
							totalTemp =
								Math.round(totalTemp * Math.pow(10, this.totalPricesDecimal)) /
								Math.pow(10, this.totalPricesDecimal);
							total += totalTemp;
						} else {
							total += 0;
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			itemsConfirmedTotalFiltered: function() {
				let total = 0;
				let confirmedPrice = 0;
				let confirmedQuantity = 0;

				if (this.po && this.filteredItems) {
					for (let key of Object.keys(this.filteredItems)) {
						if (
							this.filteredItems[key].confirmedQuantity > 0 &&
							this.filteredItems[key].confirmedPrice > 0
						) {
							confirmedQuantity = parseFloat(
								this.filteredItems[key].confirmedQuantity
							);
							confirmedPrice = parseFloat(
								this.filteredItems[key].confirmedPrice
							);
							let totalTemp = confirmedQuantity * confirmedPrice;
							let totalCountDigigts = totalTemp.toString().length;
							let totalString = totalTemp.toPrecision(
								totalCountDigigts -
									totalTemp.countDecimals() +
									this.totalPricesDecimal
							);
							totalTemp = parseFloat(totalString);
							totalTemp =
								Math.round(totalTemp * Math.pow(10, this.totalPricesDecimal)) /
								Math.pow(10, this.totalPricesDecimal);
							total += totalTemp;
						} else {
							total += 0;
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			itemsInvoicedTotal: function() {
				let total = 0;
				let invoicePrice = 0;
				let invoiceQuantity = 0;

				if (this.po && this.po.items) {
					for (let key of Object.keys(this.po.items)) {
						if (
							this.po.items[key].invoiceQuantity > 0 &&
							this.po.items[key].invoicePrice > 0
						) {
							invoiceQuantity = parseFloat(this.po.items[key].invoiceQuantity);
							invoicePrice = parseFloat(this.po.items[key].invoicePrice);
							let totalTemp = invoiceQuantity * invoicePrice;
							let totalCountDigigts = totalTemp.toString().length;
							let totalString = totalTemp.toPrecision(
								totalCountDigigts -
									totalTemp.countDecimals() +
									this.totalPricesDecimal
							);
							totalTemp = parseFloat(totalString);
							totalTemp =
								Math.round(totalTemp * Math.pow(10, this.totalPricesDecimal)) /
								Math.pow(10, this.totalPricesDecimal);
							total += totalTemp;
						} else {
							total += 0;
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			itemsInvoicedTotalFiltered: function() {
				let total = 0;
				let invoicePrice = 0;
				let invoiceQuantity = 0;

				if (this.po && this.filteredItems) {
					for (let key of Object.keys(this.filteredItems)) {
						if (
							this.filteredItems[key].invoiceQuantity > 0 &&
							this.filteredItems[key].invoicePrice > 0
						) {
							invoiceQuantity = parseFloat(
								this.filteredItems[key].invoiceQuantity
							);
							invoicePrice = parseFloat(this.filteredItems[key].invoicePrice);
							let totalTemp = invoiceQuantity * invoicePrice;
							let totalCountDigigts = totalTemp.toString().length;
							let totalString = totalTemp.toPrecision(
								totalCountDigigts -
									totalTemp.countDecimals() +
									this.totalPricesDecimal
							);
							totalTemp = parseFloat(totalString);
							totalTemp =
								Math.round(totalTemp * Math.pow(10, this.totalPricesDecimal)) /
								Math.pow(10, this.totalPricesDecimal);
							total += totalTemp;
						} else {
							total += 0;
						}
					}
				}
				return total.toFixed(this.totalPricesDecimal);
			},
			selectAllPos: {
				get: function() {
					return this.po.items && Object.keys(this.po.items).length > 0
						? Object.keys(this.po.items).length === this.selectedPos.length
						: false;
				},
				set: function(value) {
					let selected = [];

					if (value) {
						for (let key of Object.keys(this.po.items)) {
							selected.push(this.po.items[key].id);
						}
					}

					this.selectedPos = selected;
				},
			},
			canConfirm: function() {
				if (this.isProcessing) {
					return false;
				}

				if (this.isDirty) {
					return false;
				}

				if (this.poErrors && Object.keys(this.poErrors).length > 0) {
					return false;
				}

				if (!this.hasBeenSavedOnce) {
					return false;
				}

				return true;
			},
			canConsolidate: function() {
				if (this.isProcessing) {
					return false;
				}

				if (this.isDirty) {
					return false;
				}

				if (this.poErrors && Object.keys(this.poErrors).length > 0) {
					return false;
				}

				if (!this.hasBeenSavedOnce) {
					return false;
				}

				return true;
			},
			canCompleteInvoice: function() {
				if (this.isProcessing) {
					return false;
				}

				if (this.isDirty) {
					return false;
				}

				if (this.poErrors && Object.keys(this.poErrors).length > 0) {
					return false;
				}

				if (!this.hasBeenSavedOnce) {
					return false;
				}

				return true;
			},
			filteredItems: function() {
				let filteredItems = [];
				if (!this.isShowOnlyErrors) {
					if (this.po && this.po.items) {
						for (let [index, item] of this.po.items.entries()) {
							if (this.filterCPN) {
								if (item.customerProductNumber != undefined) {
									if (!item.customerProductNumber.includes(this.filterCPN)) {
										continue;
									}
								} else {
									continue;
								}
							}
							if (this.filterVPN) {
								if (item.vendorProductNumber != undefined) {
									if (!item.vendorProductNumber.includes(this.filterVPN)) {
										continue;
									}
								} else {
									continue;
								}
							}
							if (this.filterProductName) {
								if (item.summary != undefined) {
									if (!item.summary.includes(this.filterProductName)) {
										continue;
									}
								} else {
									continue;
								}
							}
							filteredItems[index] = item;
						}
					}
					filteredItems = filteredItems.filter(function(n) {
						return n != undefined;
					});
					return filteredItems;
				} else {
					for (let [index, item] of this.po.items.entries()) {
						if (this.filterCPN) {
							if (item.customerProductNumber != undefined) {
								if (!item.customerProductNumber.includes(this.filterCPN)) {
									continue;
								}
							} else {
								continue;
							}
						}
						if (this.filterVPN) {
							if (item.vendorProductNumber != undefined) {
								if (!item.vendorProductNumber.includes(this.filterVPN)) {
									continue;
								}
							} else {
								continue;
							}
						}
						if (this.filterProductName) {
							if (item.summary != undefined) {
								if (!item.summary.includes(this.filterProductName)) {
									continue;
								}
							} else {
								continue;
							}
						}
						if (item.substitutedProductVendorProductNumber) {
							if (
								!(
									this.poErrors &&
									this.poErrors[item.customerProductNumber] &&
									this.poErrors[item.customerProductNumber][
										item.vendorProductNumber
									] &&
									Object.keys(
										this.poErrors[item.customerProductNumber][
											item.vendorProductNumber
										][item.substitutedProductVendorProductNumber]
									).length > 0
								)
							) {
								continue;
							}
						} else if (
							!(
								this.poErrors &&
								this.poErrors[item.customerProductNumber] &&
								this.poErrors[item.customerProductNumber][
									item.vendorProductNumber
								] &&
								Object.keys(
									this.poErrors[item.customerProductNumber][
										item.vendorProductNumber
									]["Error"]
								).length > 0
							)
						) {
							continue;
						}
						filteredItems[index] = item;
					}
					filteredItems = filteredItems.filter(function(n) {
						return n != undefined;
					});
					return filteredItems;
				}
			},
			itemsToShow: function() {
				let filtered = this.filteredItems;

				if (!filtered || filtered.length < 0) {
					return filtered;
				}
				filtered = filtered.filter(function(n) {
					return n != undefined;
				});
				filtered = filtered.slice(
					(this.page - 1) * this.limit,
					this.page * this.limit
				);

				return filtered;
			},
			isFiltered: function() {
				return this.filterCPN || this.filterVPN || this.filterProductName;
			},
		},
		watch: {
			isShowOnlyErrors: function(newValue, oldValue) {
				if (newValue !== oldValue) {
					this.page = 1;
				}
			},
		},
		delimiters: ["<%", "%>"],
	});
}
