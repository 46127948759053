import NewPoEmail from "../components/NewPoEmail";

if (document.querySelector('.vue-po-email')) {
    Vue.component('new-po-email', NewPoEmail);

    document.poEmail = new Vue ({
        el: '.vue-po-email',
        name: 'po email',
        methods: {
            changeContent: function () {
                document.querySelector("textarea[name='po_email_content']").innerHTML = document.querySelector('.ql-editor').innerHTML;
            },
            save: function () {
                document.getElementById('emailSaveButton').click();
            }
        },
        delimiters: ["<%", "%>"]
    })
}
