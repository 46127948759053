<template>
  <div>
    <div class="modal fade" id="delete">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="popup-title">
            <span class="modal-title">Delete Order Type?</span>
          </div>
          <div class="modal-body popup-body">
            <span class="text-center">Are you sure you want to DELETE the Order Type?</span>
          </div>
          <div class="popup-buttons">
            <form :action="deleteLink" method="post">
              <button type="submit" class="btn btn-danger" style="font-weight: bold">Delete Order Type</button>
            </form>
            <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <button class="buttons-danger" data-toggle="modal" data-target="#delete" style="width: 100%">Delete Order Type</button>
  </div>
</template>

<script>
export default {
  name: "DeleteOrderType",
  methods: {
  },
  props: ['deleteLink']
}
</script>

<style scoped>

</style>
