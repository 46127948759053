<template>
  <div>
      <div class="modal fade" id="confirm">
          <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Confirming bid-sheet</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to confirm the bid-sheet?<br>
                        After confirmation no changes will be allowed.</span>
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Confirm</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
              </div>
          </div>
      </div>
    <button :disabled="! canConfirm" class="buttons" data-toggle="modal" data-target="#confirm">Confirm bid-sheet</button>
  </div>
</template>

<script>
export default {
    name: "ConfirmToPurchaser",
    methods: {
        confirm: function () {
            this.$emit('confirmed');
        }
    },
    props: ['can-confirm']
}
</script>