import BeiladerlisteSchedulerAdd from "../components/BeiladerlisteSchedulerAdd";
import VueFlashbag from '../components/VueFlashbag';

if (document.querySelector('.vue-beiladerliste-scheduler')) {
    Vue.component('beiladerliste-scheduler-add', BeiladerlisteSchedulerAdd);
    Vue.component('vue-flashbag', VueFlashbag);

    document.vueBeiladerListeAdd = new Vue({
        el: '.vue-beiladerliste-scheduler',
        name: 'Beiladerliste Scheduler Add',
        data: {
            spinner: false,
            flashbag: {
                success: [],
                error: [],
                info: [],
            },
        },
    })
}