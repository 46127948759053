<template>
    <div>
        <div class="modal fade" id="save">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Saving changes</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to save all changes?</span>
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Save</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="buttons" :disabled="! isDirty" data-toggle="modal" data-target="#save">Save</button>
    </div>
</template>

<script>
  export default {
      name: "SaveChanges",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          }
      },
      props:['is-dirty']
  }
</script>
