<template>
    <div>
        <div class="modal fade" id="cancel-po-items">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                        <span class="modal-title">Canceling items</span>
                    </div>
                    <div class="modal-body popup-body">
                        <span class="text-center">Are you sure you want to cancel the selected items? Their quantity will be set to 0.</span>
                    </div>
                    <div class="popup-buttons">
                        <button type="button" class="popup-confirm" data-dismiss="modal" @click="confirm">Cancel items</button>
                        <button type="button" class="popup-cancel" data-dismiss="modal">Abort</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="buttons" v-if="isVisible" data-toggle="modal" data-target="#cancel-po-items">Cancel Items</button>
    </div>
</template>

<script>
  export default {
      name: "CancelPoItems",
      methods: {
          confirm: function () {
              this.$emit('confirmed');
          }
      },
      props:['is-visible']
  }
</script>
