<template>
    <div class="col-2">
        <div class="modal fade" id="bulk-delete-bids">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="popup-title">
                      <span class="modal-title">Delete bid-sheets?</span>
                    </div>
                    <div class="modal-body popup-body">
                      <span class="text-center">Are you sure you want to DELETE all the selected bid-sheets?</span>
                    </div>
                    <div class="popup-buttons">
                      <form :action="deleteLink" method="post">
                        <button type="submit" class="popup-confirm-danger">Delete bids</button>
                        <input type="hidden" name="bids" :value="bids">
                      </form>
                      <button type="button" class="popup-cancel" data-dismiss="modal">Cancel</button>
                    </div>
              </div>
          </div>
        </div>
        <button class="btn btn-danger btn-xs" data-toggle="modal" data-target="#bulk-delete-bids" style="width: 100%">Bulk Delete</button>
    </div>
</template>

<script>
    export default {
        name: "BulkDeleteBids",
        methods: {
        },
        props: [
            'deleteLink',
            'bids'
        ]
    }
</script>
