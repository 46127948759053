<template>
  <div class="white-box2">
      <div class="col-10">
        <div class="row">
          <div class="col-4"><strong>Add Scheduler</strong></div>
          <div class="col-1"><strong>Subject:</strong></div>
          <div class="col"><input type="text" v-model="subject"></div>
        </div>
        <div class="row">
          <div class="col-1"><strong>Consolidator:</strong></div>
          <div class="col-3"><input type="text" v-model="consolidator"></div>
          <div class="col-1"><strong>Time of day:</strong></div>
          <div class="col"><input type="time" v-model="timeOfDay"></div>
        </div>
        <div class="row">
          <div class="col-4">
            <strong>Emails:</strong>
            <i class="fas fa-plus-circle fa-1x" @click="emails.push('')"></i>
            <ul>
              <li v-for="(email, index) of emails" :key="index">
                <input class="email-input" type="email" v-model="emails[index]">
                <i class="fas fa-minus-circle fa-1x" @click="emails.splice(index, 1)"></i>
              </li>
            </ul>
          </div>
          <div class="col-1"><strong>Weekday:</strong></div>
          <div class="col" v-for="choice in choices">
            <label class="bls-label"><input type="checkbox" class="bls-input" v-model="weekdays" :id="choice.id" :value="choice.value">{{ choice.name }}</label>
          </div>
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <button class="buttons beil-btn" @click="save">Add</button>
        <form :action="actionLink" method="post">
          <input type="hidden" name="consolidator" :value="consolidator">
          <button class="buttons beil-btn" :disabled="! consolidator"><i class="fas fa-download beil-icon"></i></button>
        </form>
      </div>
      <div class="d-flex justify-content-center spinner" v-if="spinner"></div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: "BeiladerlisteSchedulerAdd",
  data: function () {
    return {
      spinner: false,
      id: null,
      consolidator: '',
      subject: '',
      emails: [],
      timeOfDay: null,
      choices: [
        {
          'value': 1,
          'name': 'Monday'
        },
        {
          'value': 2,
          'name': 'Tuesday'
        },
        {
          'value': 3,
          'name': 'Wednesday'
        },
        {
          'value': 4,
          'name': 'Thursday'
        },
        {
          'value': 5,
          'name': 'Friday'
        },
        {
          'value': 6,
          'name': 'Saturday'
        },
        {
          'value': 7,
          'name': 'Sunday'
        },],
      weekdays: [],
    }
  },
  props: ['actionLink'],
  methods: {
    save: function() {
      this.spinner = true;
      let beiladerlisteScheduler = this.$data;
      //console.debug(beiladerlisteScheduler);
      axios.post('/settings/beiladerliste-scheduler/save', beiladerlisteScheduler)
          .then(this.saveSuccess)
          .catch(this.saveError)
          .finally(this.saveFinally);
    },
    saveSuccess: function() {
      this.$parent.flashbag.success.push("Successfully saved order type.");
      window.location.href = '/settings/beiladerliste-scheduler'
    },
    saveError: function(error) {
      console.debug(error);
      let message = 'There has been an error while saving the changes. Please check the validation errors and try again.';
      if (error.response && error.response.data && error.response.data.errors) {
        message += `\n`;
        for (let key of Object.keys(error.response.data.errors)) {
          let errorMessage = error.response.data.errors[key];

          message += `\n${key}: ${errorMessage}`;
        }
      }
      this.$parent.flashbag.error.push(message);
    },
    saveFinally: function() {
      this.spinner = false;
    },
  }
}
</script>

<style scoped>

</style>