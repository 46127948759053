const axios = require('axios').default;

import Sorter from '../components/Sorter';
import Pagination from "../components/Pagination";
import BulkDeleteBids from '../components/BulkDeleteBids';

if (document.querySelector('.vue-bid-overview')) {
    Vue.component('bulk-delete-bids', BulkDeleteBids);

    const BID_STATUS_ENUM = {
        "NEW": 1,
        "SUBMITTED": 2,
        "CONFIRMED": 3,
        "CLOSED": 4,
    };

    Vue.component('sorter', Sorter);
    Vue.component('pagination', Pagination);
    new Vue({
        el: '.vue-bid-overview',
        name: 'Bid Overview',
        data: {
            bids: [],
            bidName: "",
            vendorName: "",
            vendorCode: "",
            market: "",
            effectiveDateFrom: "",
            effectiveDateTo: "",
            usageFrom: "",
            usageTo: "",
            bidStatus: "",
            selectedBids: [],
            areBidsLoading: false,
            isProcessing: false,
            sorters: {
                bidName: null,
                vendorName: null,
                vendorCode: null,
                market: null,
                usageFrom: null,
                usageTo: null,
                bidStatus: null,
                effectiveDate: null,
            },
            orderBy: 'b.id',
            orderDir: 'DESC',
            page: 1,
            limit: 50,
            paginationInfo: null,
        },
        methods: {
            onFilterInput: function () {
                this.page = 1;
                this.fetchBids();
            },
            fetchBids: function () {
                let self = this;
                self.areBidsLoading = true;
                self.bids = [];

                axios.get('/bid/json', {
                    params: this.bidFilters
                })
                    .then(function (response) {
                        //console.log(response);
                        const data = response.data;
                        self.bids = data.items;
                        self.paginationInfo = data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                        self.areBidsLoading = false;
                    });
            },
            bulkSubmit: function() {
                this.isProcessing = true;
                axios.post('/bid/bulk-submit', this.selectedBids)
                    .then(this.bulkSubmitSuccess)
                    .catch(this.bulkSubmitError)
                    .finally(this.bulkSubmitFinally);
            },
            bulkSubmitSuccess: function(response) {
                //console.debug(response);
                this.selectedBids = [];
                this.fetchBids();
            },
            bulkSubmitError: function(error) {
                console.debug(error);
                alert("Failed to bulk-submit the selected bids.");
            },
            bulkSubmitFinally: function() {
                this.isProcessing = false;
            },
            updateSorters: function(orderBy, orderDir) {
                this.orderBy = orderBy;
                this.orderDir = orderDir;
                this.fetchBids();
            },
            canSubmit: function (bid) {
                switch (bid.bidStatus) {
                    case BID_STATUS_ENUM.NEW:
                    case BID_STATUS_ENUM.CONFIRMED:
                        return true;
                    default:
                        return false;
                }
            },
            changePage(page) {
                this.page = page;
            },
        },
        computed: {
            bidFilters: function () {
                let filters = {
                    bidName: this.bidName,
                    vendorName: this.vendorName,
                    vendorCode: this.vendorCode,
                    market: this.market,
                    effectiveDateFrom: this.effectiveDateFrom,
                    effectiveDateTo: this.effectiveDateTo,
                    usageFrom: this.usageFrom,
                    usageTo: this.usageTo,
                    bidStatus: this.bidStatus,
                    orderBy: this.orderBy,
                    orderDir: this.orderDir,
                    page: this.page,
                    limit: this.limit
                };

                return filters;
            },
            selectAllBids: {
                get: function () {
                    return (this.submittableBids && this.submittableBids.length > 0) ? this.submittableBids.length === this.selectedBids.length : false;
                },
                set: function (value) {
                    let selected = [];

                    if (value) {
                        for (let bid of this.bids) {
                            if (this.canSubmit(bid)) {
                                selected.push(bid.id);
                            }
                        }
                    }

                    this.selectedBids = selected;
                }
            },
            submittableBids: function () {
                let submittableBids = [];
                for (let bid of this.bids) {
                    if (this.canSubmit(bid)) {
                        submittableBids.push(bid);
                    }
                }
                return submittableBids;
            },
        },
        watch: {
            page: function(newPage, oldPage) {
                this.fetchBids();
            }
        },
        mounted() {
            this.fetchBids();
        },
        delimiters: ["<%", "%>"]
    });
}
