const axios = require('axios').default;
import Sorter from '../components/Sorter';
import Pagination from "../components/Pagination";

if (document.querySelector('.vue-po-overview')) {

    const PO_STATUS_ENUM = {
        "STATUS_READY_FOR_CONFIRMATION": 10,
        "STATUS_CANCELED": 20,
        "STATUS_CONFIRMED": 30,
        "STATUS_READY_FOR_CONSOLIDATION": 40,
        "STATUS_READY_FOR_INVOICE": 51,
        "STATUS_CLOSED": 60,
    };

    Vue.component('sorter', Sorter);
    Vue.component('pagination', Pagination);
    new Vue({
        el: '.vue-po-overview',
        name: 'PO Overview',
        data: {
            pos: [],
            poCode: "",
            vendorName: "",
            vendorCode: "",
            vesselName: "",
            boardingDateFrom: "",
            boardingDateTo: "",
            deliveryDateFrom: "",
            deliveryDateTo: "",
            poStatus: "",
            selectedPos: [],
            arePosLoading: false,
            isProcessing: false,
            sorters: {
                poCode: null,
                vendorName: null,
                vendorCode: null,
                location: null,
                boardingDate: null,
                deliveryDate: null,
                total: null,
                weight: null,
                poStatus: null,
            },
            orderBy: 'b.id',
            orderDir: 'DESC',
            page: 1,
            limit: 50,
            paginationInfo: null,
        },
        methods: {
            onFilterInput: function () {
                this.page = 1;
                this.fetchPos();
            },
            fetchPos: function () {
                let self = this;
                self.arePosLoading = true;
                self.pos = [];

                axios.get('/po/json', {
                    params: this.poFilters
                })
                    .then(function (response) {                        
                        const data = response.data;
                        self.pos = data.items;
                        self.paginationInfo = data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                        self.arePosLoading = false;
                    });
            },
            updateSorters: function(orderBy, orderDir) {
                this.orderBy = orderBy;
                this.orderDir = orderDir;
                this.fetchPos();
            },
            toFixed: function (value, decimalNumber) {
                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) {
                    return value;
                }

                return parsedValue.toFixed(decimalNumber || this.singlePricesDecimal); // geändert am 8.3.2022 - old - return parseFloat(parsedValue.toFixed(decimalNumber || this.singlePricesDecimal));
            },
            changePage(page) {
                this.page = page;
            },
        },
        computed: {
            poFilters: function () {
                let filters = {
                    poCode: this.poCode,
                    vendorName: this.vendorName,
                    vendorCode: this.vendorCode,
                    vesselName: this.vesselName,
                    boardingDateFrom: this.boardingDateFrom,
                    boardingDateTo: this.boardingDateTo,
                    deliveryDateFrom: this.deliveryDateFrom,
                    deliveryDateTo: this.deliveryDateTo,
                    poStatus: this.poStatus,
                    orderBy: this.orderBy,
                    orderDir: this.orderDir,
                    page: this.page,
                    limit: this.limit
                };

                return filters;
            }
        },
        watch: {
            page: function(newPage, oldPage) {
                this.fetchPos();
            }
        },
        mounted() {
            this.fetchPos();
        },
        delimiters: ["<%", "%>"]
    });
}
